import $ from 'jquery';
import GaTracking from '../core/components/ga-tracking';
export const validateEmail = ($input, $form, errorMessage, initialValue = '') => {
    $input.rules('add', {
        required: true,
        email: true,
        maxlength: 255,
        remote: {
            depends() {
                return (!$('.js__age-gate-modal').hasClass('show') &&
                    ($input.val() !== initialValue || $input.data('error-on-initial-value')));
            },
            param: {
                url: $form.data('email-availability-check'),
                type: 'POST',
                data: {
                    email: () => $input.val(),
                    token: $form.data('token'),
                },
                dataFilter(data) {
                    const parsedData = JSON.parse(data);
                    if ($input.val() === initialValue && $input.data('error-on-initial-value')) {
                        return `"${errorMessage.email_errors.same_email.currentLang}"`;
                    }
                    if (parsedData.is_closed) {
                        return `"${errorMessage.email_errors.closed_account.currentLang}"`;
                    }
                    if (!parsedData.is_valid) {
                        return `"${errorMessage.email_errors.invalid_email_use.currentLang}"`;
                    }
                    return true;
                },
                beforeSend() {
                    $.get($form.data('age-gate-status'), status => {
                        if (!status.status) {
                            window.location.reload();
                        }
                    });
                    $input.prop('disabled', true).addClass('disable');
                },
                complete() {
                    const errors = $form.validate().errorList;
                    $input.prop('disabled', false).removeClass('disable');
                    $.each(errors, (index, error) => {
                        if ($(error.element).is($input)) {
                            GaTracking.pushDataLayer({
                                event: 'systemError',
                                errorType: 'form registration',
                                errorMessage: 'Email already exists',
                            });
                            const errorDatalayer = $form.data('datalayer-on-error');
                            if (errorDatalayer) {
                                GaTracking.pushDataLayer({
                                    ...errorDatalayer,
                                    eventLabel: 'Error - Email already exists',
                                });
                            }
                        }
                    });
                },
            },
        },
        messages: {
            required: errorMessage.email_errors.invalid_email_not_blank.currentLang,
            email: errorMessage.email_errors.invalid_email.currentLang,
            maxlength: errorMessage.email_errors.max.currentLang,
        },
    });
};
export const validatePassword = ($passOne, $passTwo, errors, $form) => {
    $passOne.rules('add', {
        required: true,
        validPassword: true,
        minlength: 8,
        remote: {
            depends() {
                return !$('.js__age-gate-modal').hasClass('show') && !$passOne.is(':focus');
            },
            param: {
                url: $form.data('password-check'),
                type: 'POST',
                data: {
                    password: () => $passOne.val(),
                    token: $form.data('password-token'),
                },
                dataFilter: (data) => JSON.parse(data).is_valid,
                beforeSend() {
                    $.get($form.data('age-gate-status'), status => {
                        if (!status.status) {
                            window.location.reload();
                        }
                    });
                    $passOne.prop('disabled', true).addClass('disable');
                },
                complete() {
                    $passOne.prop('disabled', false).removeClass('disable');
                },
            },
        },
        messages: {
            required: errors.password_not_blank.currentLang,
            validPassword: '',
            minlength: '',
            remote: errors.password_remote.currentLang,
        },
    });
    $passTwo.rules('add', {
        required: true,
        equalTo: $passOne,
        messages: {
            required: errors.password_not_blank.currentLang,
            equalTo: errors.password_mismatch.currentLang,
        },
    });
};
export const requiredValidation = ($input, errors) => {
    $input.rules('add', {
        required: true,
        messages: {
            required: errors.not_blank.currentLang,
        },
    });
};
export const validateName = ($input, error) => {
    $input.rules('add', {
        required: true,
        lettersOnly: true,
        minlength: 2,
        maxlength: 255,
        messages: {
            required: error.not_blank.currentLang,
            lettersOnly: error.name_errors.numbers.currentLang,
            minlength: error.name_errors.name_min.currentLang,
            maxlength: error.name_errors.max.currentLang,
        },
    });
};
export const phoneNumberValidation = ($input, $form, error, initialValue) => {
    $input.rules('add', {
        required: true,
        minlength: 7,
        maxlength: 8,
        remote: {
            depends() {
                return !$('.js__age-gate-modal').hasClass('show') && $input.val() !== initialValue;
            },
            param: {
                url: $form.data('phone-availability-check'),
                type: 'POST',
                data: {
                    phone: () => $input.val(),
                    token: $form.data('phone-token'),
                },
                dataFilter(data) {
                    const parsedData = JSON.parse(data);
                    if (!parsedData.is_valid) {
                        return `"${error.phone_errors.invalid_phone_use.currentLang}"`;
                    }
                    return true;
                },
                beforeSend() {
                    $.get($form.data('age-gate-status'), status => {
                        if (!status.status) {
                            window.location.reload();
                        }
                    });
                    $input.prop('disabled', true).addClass('disable');
                },
                complete() {
                    const errors = $form.validate().errorList;
                    $input.prop('disabled', false).removeClass('disable');
                    $.each(errors, (index, error) => {
                        if ($(error.element).is($input)) {
                            GaTracking.pushDataLayer({
                                event: 'systemError',
                                errorType: 'form registration',
                                errorMessage: 'Phone already exists',
                            });
                            const errorDatalayer = $form.data('datalayer-on-error');
                            if (errorDatalayer) {
                                GaTracking.pushDataLayer({
                                    ...errorDatalayer,
                                    eventLabel: 'Error - Phone already exists',
                                });
                            }
                        }
                    });
                },
            },
        },
        messages: {
            required: error.not_blank.currentLang,
            minlength: error.phone_errors.phone_min.currentLang,
            maxlength: error.phone_errors.phone_max.currentLang,
        },
    });
};
$.validator.addMethod('validPassword', value => {
    const isMinLength = /^(?=.{8,})/.test(value);
    const containsLowercase = /(?=.*[a-z])/.test(value);
    const containsUppercase = /(?=.*[A-Z])/.test(value);
    const containsDecimal = /(?=.*\d)/.test(value);
    const containsSpecialChar = /(?=.*[^a-zA-Z0-9\s]).*$/.test(value);
    const matchesEnoughConditions = [containsLowercase, containsUppercase, containsDecimal, containsSpecialChar].filter(contains => contains)
        .length >= 3;
    $('.js__password-eight-symbols-validation').toggleClass('active', isMinLength);
    $('.js__password-lowercase-validation').toggleClass('active', containsLowercase);
    $('.js__password-uppercase-validation').toggleClass('active', containsUppercase);
    $('.js__password-number-validation').toggleClass('active', containsDecimal);
    $('.js__password-special-char-validation').toggleClass('active', containsSpecialChar);
    $('.js__password-conditions-validation').toggleClass('active', matchesEnoughConditions);
    return isMinLength && matchesEnoughConditions;
}, '');
$.validator.addMethod('regex', function (value, element, regexp) {
    const re = new RegExp(regexp);
    // @ts-ignore
    return this.optional(element) || re.test(value);
});
$.validator.addMethod('code', function (value, element, regexp) {
    const $elements = $(element).parent().find('input');
    let isValid = true;
    $elements.each((index, element) => {
        if (!$(element).val()) {
            isValid = false;
        }
    });
    return isValid;
});
$.validator.addMethod('fileSize', function (value, element, param) {
    // @ts-ignore
    return this.optional(element) || element.files[0].size <= param * 1024 * 1024;
});
$.validator.addMethod('fileType', function (value, element, param) {
    // @ts-ignore
    return this.optional(element) || param.includes(element.files[0].type);
});
